<template lang="pug">
main#morroAnjos
    Banner(:contents="propriedade.banners")
    SaibaMais(:contents="propriedade.saibaMais")
    Estrutura(:contents="propriedade.estrutura")
    ComoChegar(:contents="propriedade.comoChegar")
    Plantas(:contents="propriedade.implantacoes")
    Relacionados(:contents="propriedade.relacionado")
    AsideVideo(v-if="$store.state.asideVideo != null")
</template>

<script>
import { getPropriedade } from './data'

import AsideVideo from '@components/AsideVideo/AsideVideo'
import Banner from '@sections/Empreendimento/Banner/Banner'
import ComoChegar from '@sections/Empreendimento/ComoChegar/ComoChegar'
import Estrutura from '@sections/Empreendimento/Estrutura/Estrutura'
import Plantas from '@sections/Empreendimento/Plantas/Plantas'
import Relacionados from '@sections/Empreendimento/Relacionados/Relacionados'
import SaibaMais from '@sections/Empreendimento/SaibaMais/SaibaMais'

export default {
  name: 'view-empreedimento',
  components: {
    Banner,
    SaibaMais,
    Estrutura,
    ComoChegar,
    Plantas,
    Relacionados,
    AsideVideo
  },
  metaInfo() {
    return {
      title: this.propriedade.title,
      meta: this.propriedade.meta
    }
  },
  computed: {
    propriedade() {
      return getPropriedade(this.$route.params.slug)
    }
  }
}
</script>

<style lang="stylus" scoped src="./Empreendimento.styl"></style>
