<template lang="pug">
section#plantas(:class="{'only': !contents.memorial }")
    #divPlantas
        h3 Plantas e implantações
        .galeria
            BannerGaleriaTemplate(:ativo.sync="ativo", :length="contents.plantas.length")#galeria
                ul.plantas
                    li(v-for="planta, index in contents.plantas", :class="{'ativo': index == ativo}", @click="showGaleria(index)").planta
                        img(:src="planta")
                template(v-slot:nav="{ toggleBanner }")
                    ul.navPlantas
                        li(v-for="planta, index in contents.plantas", @click="toggleBanner(index)" :class="{'ativo': index == ativo}" :style="stylus").navPlanta
                            img(:src="planta").fit
    #memorial(v-if="contents.memorial")
        h3(v-if="contents.memorial.title") {{contents.memorial.title}}
        p(v-html="contents.memorial.text")
        ul
            li(v-for="item in contents.memorial.lista")
                strong(v-html="item.title")
                p(v-html="item.text")
</template>

<script>
import BannerGaleriaTemplate from '@components/BannerGaleriaTemplate/BannerGaleriaTemplate'

export default {
  name: 'section-plantas',
  props: {
    contents: {
      type: [Object, Array],
      required: true
    }
  },
  components: {
    BannerGaleriaTemplate
  },
  data() {
    return {
      ativo: 0,
      stylus: {
        transform: 'translateX(0)'
      },
      elementSize: 0
    }
  },
  mounted() {
    const element = document.getElementsByClassName('navPlanta')
    this.elementSize = element[0].offsetWidth
  },
  watch: {
    ativo: function() {
      if (this.ativo <= 0) {
        this.stylus.transform = 'translateX(0)'
      } else if (this.ativo > this.$props.contents.plantas.length - 4) {
        this.stylus.transform = `translateX(-${(this.$props.contents.plantas.length - 5) * (this.elementSize + 20)}px)`
      } else {
        this.stylus.transform = `translateX(-${(this.elementSize + 5) * this.ativo}px)`
      }
    }
  },
  methods: {
    showGaleria(index) {
      this.$store.commit('setAsideGaleria', {
        index,
        contents: this.contents.plantas
      })
    }
  }
}
</script>

<style lang="stylus" scoped src="./Plantas.styl"></style>
