export const tayayaView = {
  title: 'Tayaya Ecoview | CA2A',
  meta: [
    { name: 'description', content: 'Casas de alto padrão com vista privilegiada para até 12 pessoas' }
  ],
  slug: 'tayaya-ecoview',
  banners: [
    {
      // title: "Invista no Morro dos Anjos:",
      // lista: [
      //     "50 mil pessoas por mês visitam o Santuário Morro dos Anjos.",
      //     "Movimento o ano inteiro.",
      //     "Destino único com público fiel.",
      //     "Baixa oferta e grande procura por hotéis e resorts de alto padrão na região.",
      //     "Rentabilidade mensal.",
      //     "Garantia da propriedade imobiliária.",
      // ],
      img: require('@images/view/banner.jpg')
    }
  ],
  saibaMais: {
    tag: '',
    cor: '#00afa5',
    titulo: 'Tayaya Ecoview',
    subtitulo: 'Casas de alto padrão com vista privilegiada para até 12 pessoas',
    texto: 'Um lugar criado para aproveitar cada momento. Além de muito luxo e o conforto, o Tayayá Ecoview tem uma marina com infraestrutura completa para embarcações, que faz deste lugar um centro náutico muito especial. A privacidade das casas tem o suporte de uma grande rede hoteleira para atender aos hóspedes. O centro gastronômico é um capítulo à parte, com altíssimo nível e padrão internacional. Invista para o lazer da sua família e para lucrar com o turismo náutico o ano todo.',
    image: require('@images/view/saibamais.jpg'),
    logo: require('@svgs/tayaya_ecoview.svg'),
    link: {
      texto: 'Saiba mais em: <strong>tayaya.com.br</strong>',
      url: 'https://tayaya.com.br/'
    }
  },
  estrutura: {
    titulo: 'Estrutura',
    texto: '- Suporte de uma grande rede hoteleira <br> - Centro gastronômico de altíssimo nível e padrão internacional<br> - Infraestrutura completa para embarcações<br> - Marina: torna o lazer náutico ainda mais especial<br> - Investimento para o lazer da sua família e para lucrar com o turismo náutico o ano todo',
    image: require('@images/view/estrutura.jpg')
  },
  comoChegar: {
    titulo: 'Como Chegar',
    texto: 'Ribeirão Claro-PR <br> Às margens da Represa de Chavantes (Rio Itararé/Paranapanema), na divisa entre os estados de São Paulo e Paraná.',
    proximidades: {
      title: '',
      lista: [
        '- 170 km de Londrina',
        '- 50 km de Ourinhos',
        '- 121 km de Assis',
        '- 140 km de Marília',
        '- 145 km de Bauru',
        '- 225 km de Presidente Prudente',
        '- 282 km de Maringá',
        '- 340 km de Sorocaba',
        '- 215 km de Botucatu',
        '- 400 km de São Paulo (capital)'
      ]
    },
    images: [
      require('@images/view/comochegar01.jpg'),
      require('@images/view/comochegar02.jpg')
    ]
  },
  implantacoes: {
    plantas: [
      require('@images/view/plantas/implantacao.jpg'),
      require('@images/view/plantas/planta_01.jpg')
    ],
    memorial: null
  },
  relacionado: [
    {
      title: 'Tayayá Aqua Resort',
      link: {
        external: false,
        link: {
          name: 'Empreendimento',
          params: { slug: 'tayaya-aqua-resort' }
        }
      },
      // endereco: "Prolongamento da rodovia LZ 412, KM 4, Riberião Claro/PR",
      diferenciais: [
        'Um dos melhores resorts do Brasil', 'Apartamentos com vista para o lago'
      ],
      logo: require('@svgs/tayaya_aquaresort.svg'),
      image: require('@images/home/investments/tayaya-angradoce.jpg')
    },
    {
      title: 'Tayayá Porto Rico',
      link: {
        external: false,
        link: {
          name: 'Empreendimento',
          params: { slug: 'tayaya-porto-rico' }
        }
      },
      // endereco: "Estrada Beira Rio, Porto São José São Pedro Do Paraná/PR",
      diferenciais: [
        'Casas e apartamentos de 97 a 345 m²', '2 a 5 dormitórios'
      ],
      logo: require('@svgs/tayaya_portorico.svg'),
      image: require('@images/home/investments/tayaya-portorico.jpg')
    }
  ]
}
