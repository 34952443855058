<template lang="pug">
    section#relacionados
        h2
            span Propriedades
            |relacionadas
        .investments
            Empreendimento(:contents="investment", v-for="investment in contents", :key="investment.title").investment
</template>

<script>
import Empreendimento from '@components/ItemEmpreendimento/ItemEmpreendimento.vue';

export default {
  name: 'section-relacionados',
  components: {
    Empreendimento
  },
  props: {
    contents: {
      type: [Object, Array],
      required: true
    }
  }
}
</script>

<style lang="stylus" scoped src="./Relacionados.styl"></style>
