export const tayayaPortoRico = {
  title: 'Tayayá Porto Rico | CA2A',
  meta: [
    { name: 'description', content: 'Atrações de dia e de noite, em todas as estações' }
  ],
  slug: 'tayaya-porto-rico',
  banners: [
    {
      img: require('@images/portorico/banner02.jpg'),
      video: require('@assets/videos/tayaya_portorico_residence.mp4')
    }
    // {
    //   img: require('@images/portorico/banner01.jpg')
    // }
  ],
  saibaMais: {
    tag: '',
    cor: '#00afa5',
    titulo: 'Tayayá Porto Rico Residence & Resort',
    subtitulo: '',
    texto: [
      'Imagine um cenário paradisíaco, com praias de areia branca e águas cristalinas. Assim é Porto Rico, um dos destinos mais desejados dos paranaenses nos últimos anos e agora casa de um dos melhores e mais sofisticados empreendimentos do Brasil.',
      'Às margens do Rio Paraná, o Tayayá Porto Rico Residence & Resort reúne uma série de opções de lazer e serviços para atender aos mais variados desejos de seus moradores. Projetado pela arquiteta Juliana Meda, suas linhas contemporâneas e disruptivas convivem em harmonia com a natureza exuberante do local e os móveis da Artefacto.',
      'Aproveite essa fantástica estrutura e invista para usar, alugar no <em>pool</em> ou até mesmo trocar sua fração de tempo.'
    ],
    image: require('@images/portorico/saibamais.jpg'),
    logo: require('@svgs/tayaya_portorico.svg')
    // link: {
    //   texto: 'Saiba mais em: <strong>tayayaportorico.com.br</strong>',
    //   url: 'https://tayayaportorico.com.br'
    // }
  },
  estrutura: {
    titulo: 'Estrutura',
    texto: [
      '<strong>49.436,69 m² de área de lazer<br/>700 m² de <em>square</em></strong>',
      'A apenas 500 metros do Rio Paraná, o Tayayá Porto Rico Residence & Resort conta com uma marina exclusiva e uma vista privilegiada das suas águas de qualquer lugar do empreendimento. Entre tantas qualidades, destacam-se:',
      '<strong style="text-transform: uppercase;">Fachada verde<br/>Caminhos arborizados<br/>Brises solares na fachada<br/>Arquitetura Biofílica<br/>10.000 m² de área verde</strong>'
    ],
    image: require('@images/portorico/estrutura.jpg')
  },
  comoChegar: {
    titulo: 'Como Chegar',
    texto: 'Às margens do Rio Paraná, no limite do estado do Paraná com o Mato Grosso do Sul.',
    proximidades: {
      title: '',
      lista: [
        '- 160 km de Maringá',
        '- 227 km de Apucarana',
        '- 154 km de Umuarama',
        '- 162 km de Cianorte',
        '- 226 km de Arapongas',
        '- 251 km de Londrina',
        '- 589 km de Curitiba',
        '- 797 km de São Paulo'
      ]
    },
    fullImage: true,
    images: [
      require('@images/portorico/comochegar.jpg')
    ]
  },
  implantacoes: {
    plantas: [
      require('@images/portorico/plantas/implantacao.jpg'),
      require('@images/portorico/plantas/planta_01.png'),
      require('@images/portorico/plantas/planta_02.png'),
      require('@images/portorico/plantas/planta_03.png'),
      require('@images/portorico/plantas/planta_04.png'),
      require('@images/portorico/plantas/planta_05.png'),
      require('@images/portorico/plantas/planta_06.png'),
      require('@images/portorico/plantas/planta_07.png'),
      require('@images/portorico/plantas/planta_08.png'),
      require('@images/portorico/plantas/planta_09.png'),
      require('@images/portorico/plantas/planta_10.png'),
      require('@images/portorico/plantas/planta_11.png'),
      require('@images/portorico/plantas/planta_12.png'),
      require('@images/portorico/plantas/planta_13.png'),
      require('@images/portorico/plantas/planta_14.png'),
      require('@images/portorico/plantas/planta_15.png'),
      require('@images/portorico/plantas/planta_16.png'),
      require('@images/portorico/plantas/planta_17.png')
    ],
    memorial: {
      title: '',
      text: '<span style="text-transform: uppercase;">Apartamentos de 97,86 m² a 292,82 m²<br/>Seis opções de plantas com vista para o Rio Paraná:</span>',
      lista: [
        {
          title: '- 3 plantas tipo'
        },
        {
          title: '- 1 planta garden'
        },
        {
          title: '- 2 coberturas duplex'
        },
        {
          text: '<br/><span style="text-transform: uppercase;">Casas de 165 a 345 m²<br/>Três opções de plantas diferentes.</span>'
        },
        {
          text: '<br/><span style="text-transform: uppercase;">Todas as unidades contam com:</span>'
        },
        {
          title: '- Internet de alta velocidade Wi-Fi'
        },
        {
          title: '- Cozinha com enxoval completo'
        },
        {
          title: '- Televisão'
        },
        {
          title: '- Ar-condicionado'
        },
        {
          title: '- Sala com varanda'
        },
        {
          title: '- Frigobar'
        },
        {
          title: '- Micro-ondas'
        }
      ]
    }
  },
  relacionado: [
    {
      title: 'Tayayá Aqua Resort',
      link: {
        external: false,
        link: {
          name: 'Empreendimento',
          params: { slug: 'tayaya-aqua-resort' }
        }
      },
      // endereco: "Prolongamento da rodovia LZ 412, KM 4, Riberião Claro/PR",
      diferenciais: [
        'Um dos melhores resorts do Brasil', 'Apartamentos com vista para o lago'
      ],
      logo: require('@svgs/tayaya_aquaresort.svg'),
      image: require('@images/home/investments/tayaya-angradoce.jpg')
    },
    {
      title: 'Tayayá Ecoview',
      link: {
        external: false,
        link: {
          name: 'Empreendimento',
          params: { slug: 'tayaya-view' }
        }
      },
      // endereco: "Prolongamento da rodovia LZ 412, KM 4, Riberião Claro/PR",
      diferenciais: [
        'Casas de alto padrão', '193 m<sup>2</sup> | 3 suítes'
      ],
      logo: require('@svgs/tayaya_ecoview.svg'),
      image: require('@images/home/investments/tayaya-view.jpg')
    }
  ]
}
