<template lang="pug">
BannerGaleriaTemplate#banner(:ativo.sync="ativo", :length="contents.length")
    ul.banners
        li(v-for="banner, index in contents", :class="{'ativo': index == ativo, 'onlyImage': !banner.title}").banner
            div.img(@click="banner.video ? $store.commit('setAsideVideo', banner.video) : false")
                img(:src="banner.img")
                button(v-if="banner.video")
                    FontAwesomeIcon(:icon="icons.faCirclePlay")
            div.texto(v-if="banner.title")
                h3 {{banner.title}}
                ul.vantagens
                    li(v-for="item in banner.lista").vantagem {{item}}
</template>

<script>
import BannerGaleriaTemplate from '@components/BannerGaleriaTemplate/BannerGaleriaTemplate';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'section-banner',
  props: {
    contents: {
      type: [Object, Array],
      required: true
    }
  },
  components: {
    BannerGaleriaTemplate
  },
  data() {
    return {
      ativo: 0,
      icons: {
        faCirclePlay
      }
    }
  }
}
</script>

<style lang="stylus" scoped src="./Banner.styl"></style>
