export const morroDosAnjos = {
  title: 'Morro dos Anjos',
  meta: [
    { name: 'description', content: 'Rentabilidade, segurança e liquidez' }
  ],
  slug: 'morro-dos-anjos',
  banners: [
    {
      title: 'Invista no Morro dos Anjos:',
      lista: [
        'Você sabia que 50 mil pessoas por mês visitam o Santuário de São Miguel do Arcanjo.',
        'O turismo religioso movimenta a região o ano inteiro.',
        'Aproveite essa demanda para oferecer uma hospedagem'
      ],
      img: require('@images/morro/thumb-video-morro.jpg'),
      video: require('@assets/videos/morro_dos_anjos.mp4')
    }
  ],
  saibaMais: {
    // tag: 'Lançamento',
    cor: '00afef',
    titulo: 'Morro dos Anjos',
    subtitulo: 'Rentabilidade, segurança e liquidez',
    secondSubtitle: 'DIVERSÃO E SOFISTICAÇÃO NO MESMO ESPAÇO',
    texto: 'O complexo aquático oferece uma experiência única na região, com direito à praia artificial. O complexo gastronômico tem restaurante internacional, microcervejaria internacional e opção para todos os gostos, com cardápios assinados por renomados chefs. Além disso, o elegante projeto de Juliana Meda e os móveis da Artefacto, dão um toque todo especial ao empreendimento, com design contemporâneo, iluminação quente e acolhedora, entre outros diferenciais.',
    image: require('@images/morro/saibamais.jpg'),
    logo: require('@svgs/morro-dos-anjos.svg'),
    link: {
      texto: 'Saiba mais em: <strong>morrodosanjos.com.br</strong>',
      url: 'https://morrodosanjos.com.br/'
    }
  },
  estrutura: {
    titulo: 'DIVERSÃO O ANO INTEIRO.<br>SURPRESAS EM CADA DETALHE.',
    texto: 'A estrutura de lazer tem padrão internacional. O boliche com tecnologia sueca proporciona uma imersão incrível com mais de 50 efeitos interativos. O cinema para 18 pessoas, o playground para as crianças, as quadras poliesportivas e os jogos para o público teen são atrativos para viver momentos únicos em família. Sem falar na piscina, projetada para ser usada o ano todo. De janeiro a janeiro, coloque o Morro dos Anjos nos seus roteiros.',
    image: require('@images/morro/estrutura.jpg')
  },
  comoChegar: {
    titulo: 'COMO CHEGAR',
    texto: 'O Morro dos Anjos fica em Bandeirantes, na Rodovia BR-369, km 57, ao lado do Santuário de São Miguel do Arcanjo, um complexo religioso que recebe milhares de pessoas todos os meses. Uma boa opção é utilizar os ônibus de turismo, que têm o Morro dos Anjos como um de seus destinos.',
    proximidades: {
      title: 'Aeroportos próximos:',
      lista: [
        'Aeroporto de Londrina',
        'Aeroporto Regional de Cornélio Procópio'
      ]
    },
    images: [
      require('@images/morro/comochegar01.png'),
      require('@images/morro/comochegar02.jpg')
    ]
  },
  implantacoes: {
    plantas: [
      require('@images/morro/plantas/implantacao.jpg'),
      require('@images/morro/plantas/planta_01.png'),
      require('@images/morro/plantas/planta_02.png')
    ],
    memorial: {
      title: 'Plantas e implantações',
      text: 'Do chalé - de 70 m² - a vila – de 101 m² -, você tem estrutura para receber, confortavelmente, de 6 a 10 pessoas. As copas têm pia, frigobar e micro-ondas, os móveis tem design assinado por renomados profissionais e as varandas oferecem uma vista incrível para contemplar a natureza local.',
      lista: [
        {
          title: 'Área privativa: 70 ou 101 m²'
        //   text: 'Os chalés de 60 m² poderão acomodar até 6 pessoas e as casas vila de 90m² até 10 pessoas'
        },
        {
          title: '01 ou 02 dormitórios'
        //   text: 'Os chalés de 60 m² contam com 1 dormitório e as casas vila de 90m² com 2 dormitórios sendo 1 suíte'
        },
        {
          title: 'Sala com Varanda'
        //   text: 'Chalés possuem uma vista linda da varanda'
        },
        {
          title: 'Internet alta velocidade - Wi-fi'
        //   text: 'Todos os chalés contam com Wi-fi de alta velocidade'
        },
        {
          title: 'Televisão'
        //   text: 'Todas as acomodações do Morro dos Anjos possuem TV com diversos canais ao seu dispor'
        },
        {
          title: 'Ar Condicionado'
        //   text: 'Conte com ar condicionado para deixar o clima como desejar'
        },
        {
          title: 'Cozinha com enxoval completo'
        //   text: 'Cozinha completa com todos os utensílios necessários'
        },
        {
          title: 'Frigobar'
        //   text: 'Todas as acomodações do Morro dos Anjos possuem um frigobar ao seu dispor'
        },
        {
          title: 'Micro-ondas'
        //   text: 'Praticidade e velocidade para seus dia-a-dia durante sua estadia'
        }
      ]
    }
  },
  relacionado: [
    {
      title: 'Tayayá Aqua Resort',
      link: {
        external: false,
        link: {
          name: 'Empreendimento',
          params: { slug: 'tayaya-aqua-resort' }
        }
      },
      // endereco: "Prolongamento da rodovia LZ 412, KM 4, Riberião Claro/PR",
      diferenciais: [
        'Um dos melhores resorts do Brasil', 'Apartamentos com vista para o lago'
      ],
      logo: require('@svgs/tayaya_aquaresort.svg'),
      image: require('@images/home/investments/tayaya-angradoce.jpg')
    },
    {
      title: 'Tayayá Ecoview',
      link: {
        external: false,
        link: {
          name: 'Empreendimento',
          params: { slug: 'tayaya-view' }
        }
      },
      // endereco: "Prolongamento da rodovia LZ 412, KM 4, Riberião Claro/PR",
      diferenciais: [
        'Casas de alto padrão', '193 m<sup>2</sup> | 3 suítes'
      ],
      logo: require('@svgs/tayaya_ecoview.svg'),
      image: require('@images/home/investments/tayaya-view.jpg')
    }
  ]
}
