export const tayayaAngraDoce = {
  title: 'Tayaya Aqua Resort | CA2A',
  meta: [
    { name: 'description', content: 'Vila com elegantes casas, SPA na sacada e uma vista incrível' }
  ],
  slug: 'tayaya-aqua-resort',
  banners: [
    {
      // title: "Invista no Morro dos Anjos:",
      // lista: [
      //     "50 mil pessoas por mês visitam o Santuário Morro dos Anjos.",
      //     "Movimento o ano inteiro.",
      //     "Destino único com público fiel.",
      //     "Baixa oferta e grande procura por hotéis e resorts de alto padrão na região.",
      //     "Rentabilidade mensal.",
      //     "Garantia da propriedade imobiliária.",
      // ],
      img: require('@images/angra/banner.jpg')
    }
  ],
  saibaMais: {
    tag: '100% vendido e entregue',
    cor: '#00afa5',
    titulo: 'Tayaya Aqua Resort',
    subtitulo: 'Vila com elegantes casas, SPA na sacada e uma vista incrível',
    texto: 'Imagine um lugar com paisagens paradisíacas, relevos montanhosos com mais de 60 ilhas e 400 km de águas cristalinas repleto de quedas d´água e belezas naturais. Este é o Tayayá Aqua Resort, um empreendimento criado para atender os mais exigentes gostos. Os apartamentos de altíssimo padrão têm vista para o lago, com muito conforto, natureza, lazer e paz. Um lugar único em todos os sentidos, até na forma de comprar. Perfeito para você investir e usar.',
    image: require('@images/angra/saibamais.jpg'),
    logo: require('@svgs/tayaya_aquaresort.svg'),
    link: {
      texto: 'Saiba mais em: <strong>tayaya.com.br</strong>',
      url: 'https://tayaya.com.br'
    }
  },
  estrutura: {
    titulo: 'Estrutura',
    texto: '- Um dos únicos resorts do país com fantástico parque indoor <br> - Cenário paradisíaco repleto de belezas naturais <br> - Fantástica estrutura próxima às águas cristalinas do Rio Paraná <br> - Invista para usar, alugar no pool ou até mesmo trocar sua fração de tempo',
    image: require('@images/angra/estrutura.jpg')
  },
  comoChegar: {
    titulo: 'Como Chegar',
    texto: '- Prolongamento da Rodovia LZ 412, km 4, Riberão Claro-PR <br> - Divisa entre os estados de São Paulo e Paraná <br> - Região de fácil acesso e rodeada por águas cristalinas e paisagens deslumbrantes',
    proximidades: {
      title: '',
      lista: []
    },
    images: [
      require('@images/angra/comochegar01.jpg'),
      require('@images/angra/comochegar02.jpg')
    ]
  },
  implantacoes: {
    plantas: [
      require('@images/angra/plantas/planta_01.jpg'),
      require('@images/angra/plantas/planta_02.jpg'),
      require('@images/angra/plantas/planta_03.jpeg')
    ],
    memorial: {
      title: 'Memorial descritivo',
      text: '',
      lista: [
        {
          title: 'TAYAYÁ VILLAGE (casa para até 10 pessoas)',
          text: 'Área privativa: 113m²'
        },
        {
          title: 'TAYAYÁ AQUA SUÍTE APARTAMENTO (para até 08 pessoas)',
          text: 'Área privativa: 80m²'
        },
        {
          title: 'TAYAYÁ AQUA LUXO APARTAMENTO (para até 04 pessoas)',
          text: 'Área privativa: 40m²'
        },
        {
          title: 'FLAT STUDIO (para até 04 pessoas)',
          text: 'Área privativa: 40m²'
        },
        {
          title: 'FLAT SUÍTE (para até 06 pessoas)',
          text: 'Área privativa: 60m²'
        },
        {
          title: 'SUÍTE POUSADA (para até 16 pessoas)',
          text: 'Área privativa: 302,90m²'
        },
        {
          title: 'Internet alta velocidade Wi-fi',
          text: 'Todos os chalés contam com Wi-fi de alta velocidade'
        },
        {
          title: 'Televisão',
          text: 'Todas as acomodações do Morro dos Anjos possuem TV com diversos canais ao seu dispor'
        },
        {
          title: 'Ar Condicionado',
          text: 'Conte com ar condicionado para deixar o clima como desejar'
        },
        {
          title: 'Cozinha com enxoval completo',
          text: 'Cozinha completa com todos os utensílios necessários'
        },
        {
          title: 'Frigobar',
          text: 'Todas as acomodações do Morro dos Anjos possuem um frigobar ao seu dispor'
        },
        {
          title: 'Micro-ondas',
          text: 'Praticidade e velocidade para seus dia-a-dia durante sua estadia'
        }
      ]
    }
  },
  relacionado: [
    {
      title: 'Tayayá Porto Rico',
      link: {
        external: false,
        link: {
          name: 'Empreendimento',
          params: { slug: 'tayaya-porto-rico' }
        }
      },
      // endereco: "Estrada Beira Rio, Porto São José São Pedro Do Paraná/PR",
      diferenciais: [
        'Casas e apartamentos de 97 a 345 m²', '2 a 5 dormitórios'
      ],
      logo: require('@svgs/tayaya_portorico.svg'),
      image: require('@images/home/investments/tayaya-portorico.jpg')
    },
    {
      title: 'Tayayá Ecoview',
      link: {
        external: false,
        link: {
          name: 'Empreendimento',
          params: { slug: 'tayaya-ecoview' }
        }
      },
      // endereco: "Prolongamento da rodovia LZ 412, KM 4, Riberião Claro/PR",
      diferenciais: [
        'Casas de alto padrão', '193 m<sup>2</sup> | 3 suítes'
      ],
      logo: require('@svgs/tayaya_view.svg'),
      image: require('@images/home/investments/tayaya-view.jpg')
    }
  ]
}
