<template lang="pug">
section#saibaMais
    .image
        img(:src="contents.image").fundo
        SvgIcon(:data="contents.logo", original)
    .tag(:style="{ background: contents.cor }", v-if="contents.tag") {{contents.tag}}
    .texto
        h3(:style="{ color: contents.cor }") {{contents.titulo}}
        h4(v-if="contents.subtitulo") {{contents.subtitulo}}
        h5(v-if="contents.secondSubtitle") {{ contents.secondSubtitle }}
        p(v-html="contents.texto", v-if="typeof contents.texto === 'string'")
        p(v-for="text in contents.texto", v-html="text", v-else)
        a(:href="contents.link.url", target="_blank", rel="noopener noreferrer", v-if="contents.link")
            FontAwesomeIcon(:icon="icons.faGlobe")
            span(v-html="contents.link.texto")
</template>

<script>
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'section-saiba-mais',
  props: {
    contents: {
      type: [Object, Array],
      required: true
    }
  },
  data() {
    return {
      icons: {
        faGlobe
      }
    }
  }
}
</script>

<style lang="stylus" scoped src="./SaibaMais.styl"></style>
