<template lang="pug">
section#comoChegar(:class="{ fullImage: !contents.fullImage }")
    .wrapper
        .texto
            h3 {{contents.titulo}}
            p(v-html="contents.texto")
            ul.proximidades
                li.title {{contents.proximidades.title}}
                li(v-for="item in contents.proximidades.lista") {{item}}
        ul(v-if="!contents.fullImage").images
            li.image(v-for="image in contents.images")
                img(:src="image")
        .img(v-else)
            img(:src="contents.images[0]")
</template>

<script>
export default {
  name: 'section-como-chegar',
  props: {
    contents: {
      type: [Object, Array],
      required: true
    }
  }
}
</script>

<style lang="stylus" scoped src="./ComoChegar.styl"></style>
