<template lang="pug">
section#estrutura
    .texto
        h3(v-html="contents.titulo")
        p(v-html="contents.texto", v-if="typeof contents.texto === 'string'")
        p(v-for="text in contents.texto", v-html="text", v-else)
    .img
        img(:src="contents.image")
</template>

<script>
export default {
  name: 'section-estrutura',
  props: {
    contents: {
      type: [Object, Array],
      required: true
    }
  }
}
</script>

<style lang="stylus" scoped src="./Estrutura.styl"></style>
