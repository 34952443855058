import { morroDosAnjos } from './morro-dos-anjos'
import { tayayaAngraDoce } from './tayaya-angra-doce'
import { tayayaPortoRico } from './tayaya-porto-rico'
import { tayayaView } from './tayaya-view'

const propriedades = [morroDosAnjos, tayayaView, tayayaAngraDoce, tayayaPortoRico]

export function getPropriedade(slug) {
  return propriedades.find(item => item.slug === slug)
}
